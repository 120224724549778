import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import { HOUR, DAY, WEEK, MONTH } from "../../lib/multipliers"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorSelect,
  CalculatorTimePeriodSelect,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/disinfectants"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
const { calculator, faq } = locale
const log = logger.extend("calculator:signage")

const SECTION_KEY = "disinfectants"

const INTERVAL_OPTIONS = [
  {
    value: HOUR,
    label: "Hourly",
  },
  {
    value: DAY,
    label: "Daily",
  },
  {
    value: WEEK,
    label: "Weekly",
  },
  {
    value: MONTH,
    label: "Monthly",
  },
]

const SPRAY_BOTTLE_SIZE_OPTIONS = [
  {
    value: "16oz",
    label: "16oz",
  },
  {
    value: "24oz",
    label: "24oz",
  },
  {
    value: "32oz",
    label: "32oz",
  },
]
const LIQUID_BOTTLE_SIZE_OPTIONS = [
  {
    value: "24oz",
    label: "24oz",
  },
  {
    value: "48oz",
    label: "48oz",
  },
  {
    value: "100oz",
    label: "100oz",
  },
  {
    value: "128oz",
    label: "128oz (1 gallon)",
  },
]

const INDIVIDUAL_WIPE_CONTAINER_SIZE_OPTIONS = [
  {
    value: 50,
    label: "50 wipes",
  },
  {
    value: 100,
    label: "100 wipes",
  },
  {
    value: 200,
    label: "200 wipes",
  },
]

const RESET_KEYS = [
  "disinfectants_wipes_per_time_period",
  "disinfectants_spray_bottles_per_time_period",
  "disinfectants_liquid_cleaners_per_time_period",
]

const CalculatorDisinfectants = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.disinfectants}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.disinfectants}</h2>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="disinfectants_wipes_containers_total" />
            <div className="text-sm">Disinfectant wipe containers</div>
          </div>
          <div>
            <CalculatorTotal totalKey="disinfectants_spray_bottles_total" />
            <div className="text-sm">Spray bottles</div>
          </div>
          <div>
            <CalculatorTotal totalKey="disinfectants_liquid_cleaners_total" />
            <div className="text-sm">Liquid cleaner bottles</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <fieldset className="mb-5">
                On a{" "}
                <CalculatorTimePeriodSelect
                  className="inline-block"
                  name="disinfectants_time_period"
                  options={INTERVAL_OPTIONS}
                  register={register}
                />{" "}
                basis, my business needs:
              </fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="disinfectants_wipes_per_time_period"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="disinfectants_wipes_per_time_period"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="disinfectants_wipes_per_time_period">
                  individual disinfectant wipes
                </label>
              </Fieldset>

              <fieldset className="mb-5">
                sized in{" "}
                <CalculatorSelect
                  className="inline-block"
                  name="disinfectants_wipes_per_container"
                  options={INDIVIDUAL_WIPE_CONTAINER_SIZE_OPTIONS}
                  register={register}
                />{" "}
                containers
              </fieldset>

              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="disinfectants_spray_bottles_per_time_period"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="disinfectants_spray_bottles_per_time_period"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="disinfectants_sprays_bottle_size">
                  spray bottles, sized{" "}
                  <CalculatorSelect
                    className="inline-block"
                    name="disinfectants_sprays_bottle_size"
                    options={SPRAY_BOTTLE_SIZE_OPTIONS}
                    register={register}
                  />
                </label>
              </Fieldset>

              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="disinfectants_liquid_cleaners_per_time_period"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="disinfectants_liquid_cleaners_per_time_period"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="disinfectants_liquid_cleaners_per_time_period">
                  liquid cleaner bottles, sized{" "}
                  <CalculatorSelect
                    className="inline-block"
                    name="disinfectants_liquid_cleaners_bottle_size"
                    options={LIQUID_BOTTLE_SIZE_OPTIONS}
                    register={register}
                  />
                </label>
              </Fieldset>
              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>
            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorDisinfectants
