import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading:
      "What is the difference between a spray bottle and a liquid cleaner bottle?",
    body: (
      <>
        <p>
          A spray bottle is one that can squirt, spray or mist fluids through a
          handle. Liquid bottles of cleaners and disinfectants are usually large
          bottles that can be poured into mop containers, spray bottles, or
          smaller dispensing containers.
        </p>
      </>
    ),
  },
  {
    heading: "How should disinfectants be used?",
    body: (
      <>
        <p>
          As per{" "}
          <OutboundLink href="https://www.osha.gov/SLTC/covid-19/retail.html">
            OSHA
          </OutboundLink>
          , all surfaces and equipment should be regularly cleaned and
          disinfected. Protocols should be established to disinfect
          frequently-touched surfaces in workspaces and public-facing areas. For
          example, these objects should be wiped down between each customer:
        </p>
        <ul>
          <li>credit card terminals,</li>
          <li>pens, and</li>
          <li>styluses</li>
        </ul>
        <p>
          Additionally, worker-facing objects should be cleaned at least as
          often as workers change workstations – such as,
        </p>
        <ul>
          <li>worker-facing touch screens,</li>
          <li>keyboards, and</li>
          <li>other equipment</li>
        </ul>
        <p>
          Importantly, door push bars and handles should also be cleaned
          frequently.
        </p>
        <p>
          When practical, customers may be given wipes and asked to clean
          equipment before or after use in order to reduce worker exposure.
        </p>
      </>
    ),
  },
  {
    heading: "What should you look for when shopping for disinfectants?",
    body: (
      <>
        <p>
          As per{" "}
          <OutboundLink href="https://www.osha.gov/news/newsreleases/national/05042020">
            OSHA
          </OutboundLink>
          , all disinfectants used should either have label claims against the
          coronavirus or should contain cleaning chemicals from the
          Environmental Protection Agency-approved List N, which can be accessed{" "}
          <OutboundLink href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-coronavirus-covid-19">
            here
          </OutboundLink>
          .
        </p>
      </>
    ),
  },
]

const DisinfectantsContent = () => <Content sections={sections} />

export default DisinfectantsContent
